/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/main.scss";

// REFER: https://github.com/gatsbyjs/gatsby/issues/19488
export const onInitialClientRender = () => {
  // REFER: https://developer.chrome.com/blog/history-api-scroll-restoration
  if ('scrollRestoration' in window.history) {
    // Back off, browser, I got this...
    window.history.scrollRestoration = 'manual';
  }
  window.scroll({
    behavior: 'instant',
    top: 0,
    left: 0
  })
};
